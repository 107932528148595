<template>
    <div data-scroll="fade-up"> 
      <section class="c-card c-card-bk-lightblue">
          <p class="c-card-bk-lightblue-content" v-html="content.text"></p>
      </section>
    </div>
</template>

<script>
export default {
  name: 'MarketsDetail',
  props: {
    content: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
};
</script>
