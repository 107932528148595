<template>
	<main class="markets">
		<!--
    //# Section Title -->
		<section class="l-title align-center" data-scroll-section>
			<h1
				class="title title-general"
				data-scroll="fade-up"
				data-scroll-delay="1"
			>
				The Markets
			</h1>
			<div class="text-c" data-scroll="fade-up" data-scroll-delay="2">
				<p class="text" style="text-align: justify">
					Perhaps the best way to understand Ascentador is to hear the voices of
					those who have direct experience of our Team and Model. We are proud
					to provide a selection of those here.
				</p>
			</div>
		</section>
		<!--
    //# Section Logos -->
		<section class="l-logos align-left" data-scroll-section>
			<ul class="logos-list" data-scroll="fade-up" data-scroll-delay="3">
				<li
					class="logo-item"
					v-for="item in content"
					:key="item.orderScroll"
					data-scroll="fade-up"
					:data-scroll-delay="item.orderScroll"
				>
					<a @click="contentHandler(item)" class="c-card">
						<div
							:class="{
								'logo-item--active': active.orderScroll == item.orderScroll,
							}"
							class="logo-image c-card"
						>
							<ImageItem
								alt="Markets"
								:source="require(`@/assets/markets/markets-${item.name}.jpg`)"
							/>
						</div>
					</a>
				</li>
			</ul>
		</section>

		<!-- DESKTOP -->
		<section class="marketsDetail" id="marketsDetail" data-scroll-section>
			<MarketsDetail
				v-if="active"
				:content="active"
				:key="active.orderScroll"
			/>
		</section>

		<!-- MOBILE   -->
		<section class="l-modal-markets">
			<MarketsModal ref="MarketsModal" @keep-scrolling="keepScrolling" />
		</section>
	</main>
</template>

<script>
import MarketsModal from "./MarketsModal";
import MarketsDetail from "./MarketsDetail";
import ImageItem from "@/components/ImageItem";

export default {
	name: "Markets",
	metaInfo() {
		return {
			title: "Ascentador | The Markets",
			meta: [
				{
					name: "description",
					content:
						"Our clients are in every region of the world and cut across many industries, sectors and governance structures. What unites them all is their desire for a more effective approach to solving problems.",
				},
			],
		};
	},
	components: {
		MarketsModal,
		MarketsDetail,
		ImageItem,
	},
	data() {
		return {
			active: null,
			detailOpen: true,
			content: [
				{
					name: "brockmans",
					text: `
          <p class="text text-detail">“Ascentador is one of a kind. From the first discussions through to the successful appointment of the perfect candidate, Chris always felt totally as one of our team, insightful, flexible, and responsive. Proactive follow-up and regular touch-base conversations to make sure we were always fully aligned from the brief to the shortlist. An outstanding client experience.”</p>
          <p class="text text-signature">Neil Everitt - Chairman  <br/> Brockmans Gin</p>
        `,
					image: "@/assets/markets/markets-brockmans.jpg",
					orderScroll: 1,
				},
				{
					name: "callaway",
					text: `
          <p class="text text-detail">“I have known and worked with Chris van Someren and his team across several organizations I have led as CEO. Their responsiveness to business demands has been outstanding. I never had any concern that my priorities were anything but top of mind. Chris has shown a very high level of insight as it relates to an executive’s strengths and weaknesses and the likelihood of their fit into an organization. His broad international experience provides a very sound platform from which to judge needs of a wide range of businesses. He views the relationship with his client more like a partnership than a transaction so his commitment to success is that much greater. I’m quite a fan of Chris’ and would feel quite comfortable getting him involved with any organization with which I’m affiliated.”</p>
          <p class="text text-signature">George Fellows — Chief Executive Officer<br/> Callaway Golf Corporation</p>
        `,
					image: "@/assets/markets/markets-callaway.jpg",
					orderScroll: 2,
				},
				{
					name: "cdm",
					text: `
          <p class="text text-detail">“I have known and worked with Chris van Someren and his team for more than 10 years. Chris has not only over-delivered on the searches he has conducted on my behalf, but I have learned a great deal from his perspective. He and his team are strategic business partners, working hard with innovative techniques to deeply understand the underlying business issues we need to solve.”</p>
          <p class="text text-signature">Ed Wise, Chairman & CEO <br/> The CDM Group</p>
          `,
					image: "@/assets/markets/markets-cdm.jpg",
					orderScroll: 3,
				},
				{
					name: "dragon-capital",
					text: `
          <p class="text text-detail">“Our collaboration with Ascentador started in the context of succession planning, involving individual assessments including feedback and career development. The quality of work and the insights provided in this context were outstanding, evidenced by the fact that about 1/3 of the assessed individuals subsequently evolved to join the C-Suite. Select individuals were managed out which also greatly helped the effectiveness of our organization. Given the excellent experience with Ascentador we expanded our collaboration which paid us dividends in the form of improved staff engagement and satisfaction under the new leadership.”</p>
          <p class="text text-signature">Beat Schuerch, CEO <br/> Dragon Capital</p>
          `,
					image: "@/assets/markets/markets-dragon-capital.jpg",
					orderScroll: 4,
				},
				{
					name: "emmert",
					text: `
          <p class="text text-detail">“Chris van Someren and his team at Ascentador exceeded our expectations in every way.  During the interview process, we were continually complimented by the candidates on the process and feedback that they received every step of the way.  Ascentador also helped us with many HR issues that we had been struggling with for years.  Chris and his team developed long-term solutions that we were able to implement and from which we will benefit for many years to come. We have a newfound excitement and optimism for our business because of the work that Ascentador has done for us.”</p>
          <p class="text text-signature">- Jerry Emmert, Partner <br/> Emmert &amp; Sons</p>
          `,
					image: "@/assets/markets/markets-emmert.jpg",
					orderScroll: 5,
				},
				{
					name: "ferrero",
					text: `
          <p class="text text-detail">“When I met Ascentador, it was with the notion that I was meeting a recruiter with a job in mind.  What in fact happened was I met a group of people that went beyond a job.  Chris and his team took the time to understand my career and life objectives while at the same time listened to me with deep conviction when I made comments about their progress.  This relationship of mutual respect is rare.  I believe that Ascentador allows a candidate to endure and succeed by ensuring that the match between client and candidate is even better than both originally realize because they listen, deeply and with conviction, then act with haste and forethought. I look forward to continue my journey with them.”</p>
          <p class="text text-signature">- Raj Rao, VP – Open Innovation Asia<br/> Ferrero</p>
          `,
					image: "@/assets/markets/markets-ferrero.jpg",
					orderScroll: 6,
				},
				{
					name: "firmenich",
					text: `
          <p class="text text-detail">“When I met Ascentador for the first time, I could see they were a great team of highly professional individuals with nothing to distinguish them but their great experience and bright minds. I was only partially right because, on top of these qualities, I experienced for myself that their unique approach to hunting talent delivered exactly what I needed, just where and when I needed it. This bespoke delivery is their immense value proposition. Impressive and amazing.”</p>
          <p class="text text-signature">- Aldo Uva, President - Flavors Division <br/> Firmenich</p>
          `,
					image: "@/assets/markets/markets-firmenich.jpg",
					orderScroll: 7,
				},
				{
					name: "hsc",
					text: `
          <p class="text text-detail">“I first had the pleasure of working with Chris van Someren and his team when he was brought in to help our company with CEO succession planning. After weeks of interviewing the company’ s senior managers, Ascentador delivered comprehensive assessments of the team, complete with individual development plans and an implementation roadmap. The final recommendations were a quality piece of consulting work, extremely analytical, insightful, critical, and even controversial at places, but undeniably objective and well thought - out. We were able to implement most of Chris’ recommendations, which resulted in a successful transition of leadership at HSC. During the process, I came to enjoy working with Chris(as did many of our team members), particularly his thought - provoking questions, but it was his genuineness and his ability to show empathy
          for others that earned Chris our trust and made him an effective consultant.From that project, we moved on to work with him on several projects, including improving senior executive hiring and more importantly, CEO coaching, under which Chris and I engage in weekly discussions on several issues. Chris’ insights during those sessions are of great value to me as I review and reflect upon our managerial challenges from different perspectives. Despite the distance and travel limits due to the pandemic, we have worked effectively and grown our mutual professional respect
          for one another. Based on my own experience in working with him, I would not hesitate to recommend Chris as a great organizational and professional development partner.”</p>
          <p class="text text-signature">Trinh Hoai Giang, Chief Executive Officer <br/>
          Ho Chi Minh City Securities Corporation (HSC)</p>
          `,
					image: "@/assets/markets/markets-hsc.jpg",
					orderScroll: 8,
				},
				{
					name: "manifest",
					text: `
          <p class="text text-detail">“Chris van Someren and his team consistently deliver excellence.  Every engagement with Ascentador has provided me with unique market insights. Chris makes a deep investment to understand your company and its goals and is the most responsive professional I have encountered.  He is both a master strategist and technician.  Importantly, his team has true reverence for the candidate experience each and every search.”</p>
          <p class="text text-signature">- David Barron, COO/CFO <br/>Manifest</p>
          `,
					image: "@/assets/markets/markets-manifest.jpg",
					orderScroll: 9,
				},
				{
					name: "reckitt",
					text: `
          <p class="text text-detail">“In the past years I worked as client and candidate with several recruitment companies, none ever equaled Chris and his team, to me they are absolutely outstanding. Ascentador personalizes the recruitment approach according to every client/candidate context, making you feel full trust and confidence that they take both sides interest at heart and never compromise on doing the right thing. I unreservedly recommend Chris and his team.”</p>
          <p class="text text-signature">- Dr. Leyla Boumghar-Bourchai, Regional R&amp;D Director <br/> Reckitt-Benckiser</p>
          `,
					image: "@/assets/markets/markets-reckitt.jpg",
					orderScroll: 10,
				},
			],
		};
	},
	methods: {
		openModal(content) {
			this.$refs.MarketsModal.open(content);
			this.$emit("stop-scrolling");
		},
		showCurrentPartnerInfo(content) {
			this.active = content;
		},
		contentHandler(content) {
			if (window.innerWidth < 1400) {
				this.openModal(content.text);
			} else {
				this.$emit("scroll-to", "#marketsDetail", -450);
				this.showCurrentPartnerInfo(content);
			}
		},
		keepScrolling() {
			this.$emit("keep-scrolling");
		},
	},
	mounted() {
		this.showCurrentPartnerInfo(this.content[0]);
		this.$emit("view-mounted");
	},
};
</script>
