<template>
	<transition name="modal-fade">
		<div v-show="active" class="modal-bk">
			<div class="modal c-card c-card-bk-lightblue">
				<header class="modal-header">
					<slot name="header">
						<button type="button" class="btn-close-white" @click="close"></button>
					</slot>
				</header>
				<section class="modal-body">
					<slot name="body">
						<div class="text-c" v-html="content"></div>
					</slot>
				</section>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "MarketsModal",
	data() {
		return {
			content: "",
			active: false,
		};
	},
	methods: {
		open(content) {
			this.content = content;
			this.active = true;
		},
		close() {
			this.active = false;
			this.$emit("keep-scrolling");
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-bk {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.82);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
}
.modal {
	padding: 2.2rem 3.4rem 3.2rem 3.4rem;
	max-width: 72rem;
	width: 100%;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
}
.modal-header {
	padding: 15px;
	display: flex;
	margin-bottom: 3rem;
	position: relative;
	justify-content: space-between;
}
.modal-body {
	position: relative;
}
.btn-close-white {
	width: 3.2rem;
	height: 3.2rem;
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	background: url("../../assets/icon-close-white.svg") no-repeat center center /
		100% auto;
	cursor: pointer;
	font-weight: bold;
}
// Transition
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
